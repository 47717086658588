import { toast } from '@/components/RMToast/RMToast';

import { writeToClipboard } from './clipboard';

export interface ShareSheetData {
  url: string | Promise<string>;
  title?: string;
}

export type ShareResult = 'shared' | 'copied-to-clipboard' | 'aborted';

export async function openShareSheet(data: ShareSheetData): Promise<ShareResult> {
  if (navigator.share == null || navigator.canShare == null || navigator.canShare() == false) {
    await writeToClipboard(data.url);
    return 'copied-to-clipboard';
  }

  try {
    await navigator.share({
      title: data.title,
      // The url can also be a promise so the api is basically the same as the `writeToClipboard`
      url: await data.url,
    });
    return 'shared';
  } catch (error) {
    if (error instanceof Error && error.name == 'AbortError') {
      return 'aborted';
    }
    throw error;
  }
}

export function showShareSheetToast(result: ShareResult, text = 'Link copied to clipboard') {
  switch (result) {
    case 'copied-to-clipboard': {
      toast(text);
      break;
    }
    default: {
      break;
    }
  }
}
